import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from '@angular/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class Form940Service {

  constructor(private http: HttpClient) { }

  // Form 940 Get Filter Data, Report Data & Download API
  getreferencedata940() {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.apiUrl}` + `940/getreferencedata`)
      .pipe(map((res: Response) => res));
  }
  report940Data(newvalue: any) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(`${environment.apiUrl}` + `940/getreportdata`, newvalue)
      .pipe(map((res: Response) => res));
  }
  updateform940(newValue: any) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.apiUrl}` + `940/updateForm940Data`, newValue).subscribe(res => {
    });
  }
  form940DataDetail(newvalue: any) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.apiUrl}` + `940/getreportdatabyid/` + newvalue)
      .pipe(map((res: Response) => res));
  }
  getForm940Pdf(newvalue: any) {
    const value = newvalue;
    const fileName = `${environment.apiUrl}` + `940/download940PdfFile`;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(fileName, newvalue, { headers: headers, responseType: 'blob' as 'json' })
      .pipe(map((res: any) => {
        const a = document.createElement('a');
        document.body.appendChild(a);
        const blob = new Blob([res.body], { type: 'application/zip' });
        const downloadUrl = URL.createObjectURL(res);
        window.open(downloadUrl);
      })
      );
  }
  getForm940Csv(newvalue: any) {
    const value = newvalue;
    const fileName = `${environment.apiUrl}` + `940/download940csvFile`;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(fileName, newvalue, { headers: headers, responseType: 'blob' as 'json' })
      .pipe(map((res: any) => {
        const a = document.createElement('a');
        document.body.appendChild(a);
        const blob = new Blob([res.body], { type: 'application/zip' });
        const downloadUrl = URL.createObjectURL(res);
        window.open(downloadUrl);
      })
      );
  }
  getForm940Json(newvalue: any) {
    const value = newvalue;
    const fileName = `${environment.apiUrl}` + `940/download940JsonFile`;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(fileName, newvalue, { headers: headers, responseType: 'blob' as 'json' })
      .pipe(map((res: any) => {
        const a = document.createElement('a');
        document.body.appendChild(a);
        const blob = new Blob([res.body], { type: 'application/zip' });
        const downloadUrl = URL.createObjectURL(res);
        window.open(downloadUrl);
      })
      );
  }
  getForm940Excel(newvalue: any) {
    const value = newvalue;
    const fileName = `${environment.apiUrl}` + `940/download940excelFile`;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(fileName, newvalue, { headers: headers, responseType: 'blob' as 'json' })
      .pipe(map((res: any) => {
        const a = document.createElement('a');
        document.body.appendChild(a);
        const blob = new Blob([res.body], { type: 'application/zip' });
        const downloadUrl = URL.createObjectURL(res);
        window.open(downloadUrl);
      })
      );
  }
  getForm940Xml(newvalue: any) {
    const value = newvalue;
    const fileName = `${environment.apiUrl}` + `940/download940XmlFile`;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(fileName, newvalue, { headers: headers, responseType: 'blob' as 'json' })
      .pipe(map((res: any) => {
        const a = document.createElement('a');
        document.body.appendChild(a);
        const blob = new Blob([res.body], { type: 'application/zip' });
        const downloadUrl = URL.createObjectURL(res);
        window.open(downloadUrl);
      })
      );
  }
}
