import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from '@angular/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class Form941Service {

  constructor(private http: HttpClient) { }

  // Form 941 Get Filter Data, Report Data & Download API
  getreferencedata941() {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.apiUrl}` + `941/getreferencedata`)
      .pipe(map((res: Response) => res));
  }
  reportData941(newvalue: any) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(`${environment.apiUrl}` + `941/getreportdata/`, newvalue)
      .pipe(map((res: Response) => res));
  }
  form941DataDetail(newvalue: any) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.apiUrl}` + `941/getreportdata/` + newvalue)
      .pipe(map((res: Response) => res));
  }
  getForm941Pdf(newvalue: any) {
    const value = newvalue;
    const fileName = `${environment.apiUrl}` + `941/download941PdfFile`;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(fileName, newvalue, { headers: headers, responseType: 'blob' as 'json' })
      .pipe(map((res: any) => {
        const a = document.createElement('a');
        document.body.appendChild(a);
        const blob = new Blob([res.body], { type: 'application/zip' });
        const downloadUrl = URL.createObjectURL(res);
        window.open(downloadUrl);
      })
      );
  }
  getForm941Csv(newvalue: any) {
    const value = newvalue;
    const fileName = `${environment.apiUrl}` + `941/download941CsvFile`;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(fileName, newvalue, { headers: headers, responseType: 'blob' as 'json' })
      .pipe(map((res: any) => {
        const a = document.createElement('a');
        document.body.appendChild(a);
        const blob = new Blob([res.body], { type: 'application/zip' });
        const downloadUrl = URL.createObjectURL(res);
        window.open(downloadUrl);
      })
      );
  }
  getForm941Excel(newvalue: any) {
    const value = newvalue;
    const fileName = `${environment.apiUrl}` + `941/download941CsvFile`;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(fileName, newvalue, { headers: headers, responseType: 'blob' as 'json' })
      .pipe(map((res: any) => {
        const a = document.createElement('a');
        document.body.appendChild(a);
        const blob = new Blob([res.body], { type: 'application/zip' });
        const downloadUrl = URL.createObjectURL(res);
        window.open(downloadUrl);
      })
      );
  }
  getForm941Xml(newvalue: any) {
    const value = newvalue;
    const fileName = `${environment.apiUrl}` + `941/download941Xmlfile`;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(fileName, newvalue, { headers: headers, responseType: 'blob' as 'json' })
      .pipe(map((res: any) => {
        const a = document.createElement('a');
        document.body.appendChild(a);
        const blob = new Blob([res.body], { type: 'application/zip' });
        const downloadUrl = URL.createObjectURL(res);
        window.open(downloadUrl);
      })
      );
  }
  getForm941Json(newvalue: any) {
    const value = newvalue;
    const fileName = `${environment.apiUrl}` + `941/download941Jsonfile`;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(fileName, newvalue, { headers: headers, responseType: 'blob' as 'json' })
      .pipe(map((res: any) => {
        const a = document.createElement('a');
        document.body.appendChild(a);
        const blob = new Blob([res.body], { type: 'application/zip' });
        const downloadUrl = URL.createObjectURL(res);
        window.open(downloadUrl);
      })
      );
  }
}
