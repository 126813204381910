import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from '@angular/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class employeedeductionService {

  constructor(private http: HttpClient) { }
  fetchData(data: any) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(`${environment.clientPayrollapiUrl}` + `deduction-register-by/reference-data`, data,)
      .pipe(map((res: Response) => res));
  }
  getfilterData(filterData: any) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(`${environment.clientPayrollapiUrl}` + `EmployeeCheckStubHistory/search-filter`, filterData,)
      .pipe(map((res: Response) => res));
  }
  reportdataSummary(newvalue: any) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(`${environment.clientPayrollapiUrl}` + `deduction-register-by/employee-data`, newvalue,)
      .pipe(map((res: Response) => res));
  }
  pdfSummary(newvalue: any) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(`${environment.clientPayrollapiUrl}` + `deduction-register-by/employee-pdf`, newvalue,
      { headers: headers, responseType: 'blob' as 'json' })
      .pipe(map((res: any) => {
        const a = document.createElement('a');
        document.body.appendChild(a);
        const blob = new Blob([res.body], { type: 'application/pdf' });
        const downloadUrl = URL.createObjectURL(res);
        window.open(downloadUrl);
      })
      );
  }
  employeedataSummary(newvalue: any) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(`${environment.clientPayrollapiUrl}` + `deduction-register-by/deduction-data`, newvalue,)
      .pipe(map((res: Response) => res));
  }
  downloadpdfSummary(newvalue: any) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(`${environment.clientPayrollapiUrl}` + `deduction-register-by/deduction-pdf`, newvalue,
      { headers: headers, responseType: 'blob' as 'json' })
      .pipe(map((res: any) => {
        const a = document.createElement('a');
        document.body.appendChild(a);
        const blob = new Blob([res.body], { type: 'application/pdf' });
        const downloadUrl = URL.createObjectURL(res);
        window.open(downloadUrl);
      })
      );
  }
  excelSummary(newvalue: any) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(`${environment.clientPayrollapiUrl}` + `deduction-register-by/employee-excel`, newvalue,
      { headers: headers, responseType: 'blob' as 'json' })
      .pipe(map((res: any) => {
        const a = document.createElement('a');
        document.body.appendChild(a);
        const blob = new Blob([res.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const downloadUrl = URL.createObjectURL(res);
        window.open(downloadUrl);
      })
      );
  }
  downloadexcelSummary(newvalue: any) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(`${environment.clientPayrollapiUrl}` + `deduction-register-by/deduction-excel`, newvalue,
      { headers: headers, responseType: 'blob' as 'json' })
      .pipe(map((res: any) => {
        const a = document.createElement('a');
        document.body.appendChild(a);
        const blob = new Blob([res.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const downloadUrl = URL.createObjectURL(res);
        window.open(downloadUrl);
      })
      );
  }
  downloadexcelSummarypro(newvalue: any) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(`${environment.clientPayrollapiUrl}` + `deduction-register-by/summary-excel`, newvalue,
      { headers: headers, responseType: 'blob' as 'json' })
      .pipe(map((res: any) => {
        const a = document.createElement('a');
        document.body.appendChild(a);
        const blob = new Blob([res.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const downloadUrl = URL.createObjectURL(res);
        window.open(downloadUrl);
      })
      );
  }
}
