import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from '@angular/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class Form1099miscService {

  constructor(private http: HttpClient) { }

  // Form 1099 Get Filter Data, Report Data & Download API
  getreferencedata1099() {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.apiUrl}` + `1099Misc/get1099reportreferencedata`)
      .pipe(map((res: Response) => res));
  }
  reportData1099(newvalue: any) {
    const localbuffer = newvalue;
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(`${environment.apiUrl}` + `1099Misc/get1099reportdata/`, newvalue)
      .pipe(map((res: Response) => res));
  }
  getfrom1099Pdf(newvalue: any) {
    const value = newvalue;
    const fileName = `${environment.apiUrl}` + `1099Misc/download1099pdfFile`;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(fileName, newvalue, { headers: headers, responseType: 'blob' as 'json' })
      .pipe(map((res: any) => {
        const a = document.createElement('a');
        document.body.appendChild(a);
        const blob = new Blob([res.body], { type: 'application/zip' });
        const downloadUrl = URL.createObjectURL(res);
        window.open(downloadUrl);
      })
      );
  }
  getfrom1099Csv(newvalue: any) {
    const value = newvalue;
    const fileName = `${environment.apiUrl}` + `1099Misc/download1099csvFile`;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(fileName, newvalue, { headers: headers, responseType: 'blob' as 'json' })
      .pipe(map((res: any) => {
        const a = document.createElement('a');
        document.body.appendChild(a);
        const blob = new Blob([res.body], { type: 'application/zip' });
        const downloadUrl = URL.createObjectURL(res);
        window.open(downloadUrl);
      })
      );
  }
  form1099miscdetail(newvalue: any) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.apiUrl}` + `1099Misc/get1099reportdatabyid/` + newvalue)
      .pipe(map((res: Response) => res));
  }
  getfrom1099Excel(newvalue: any) {
    const value = newvalue;
    const fileName = `${environment.apiUrl}` + `1099Misc/download1099excelFile`;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(fileName, newvalue, { headers: headers, responseType: 'blob' as 'json' })
      .pipe(map((res: any) => {
        const a = document.createElement('a');
        document.body.appendChild(a);
        const blob = new Blob([res.body], { type: 'application/zip' });
        const downloadUrl = URL.createObjectURL(res);
        window.open(downloadUrl);
      })
      );
  }
  getfrom1099Xml(newvalue: any) {
    const value = newvalue;
    const fileName = `${environment.apiUrl}` + `1099Misc/download1099XmlFile`;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(fileName, newvalue, { headers: headers, responseType: 'blob' as 'json' })
      .pipe(map((res: any) => {
        const a = document.createElement('a');
        document.body.appendChild(a);
        const blob = new Blob([res.body], { type: 'application/zip' });
        const downloadUrl = URL.createObjectURL(res);
        window.open(downloadUrl);
      })
      );
  }
  getfrom1099Json(newvalue: any) {
    const value = newvalue;
    const fileName = `${environment.apiUrl}` + `1099Misc/download1099JsonFile`;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(fileName, newvalue, { headers: headers, responseType: 'blob' as 'json' })
      .pipe(map((res: any) => {
        const a = document.createElement('a');
        document.body.appendChild(a);
        const blob = new Blob([res.body], { type: 'application/zip' });
        const downloadUrl = URL.createObjectURL(res);
        window.open(downloadUrl);
      })
      );
  }
}
